:root {
    --color-primary: teal;
    --color-surface: white;
    --color-solid: black;
}
@font-face {
    font-family: titleFont;
    src: url(/static/media/D3Euronism.8177ea78.ttf);
}
.mainMenu{
    width: 100%;
}
.ui.secondary.inverted.pointing.menu .active.item{
    border-color: transparent !important;
}
body{
    font-size: 15px!important;
}
a{
    color:teal;
    color:var(--color-primary);
}
.ui.list > .item a.header{
    color:teal !important;
    color:var(--color-primary) !important;
}
.welcome{
    background-color: teal;
    background-color: var(--color-primary);
}
.welcome-box, .welcome{
    min-height: 30vh;
}
.introName{
    color: #fff;
    font-size: 4em;
    font-family: "titleFont", 'boldbold',PT Sans, "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 900;
    line-height: 1em!important;
}
.sloganText{
    color: #ffffff;
    font-size: 1em;
    min-height: 50px;
    font-weight: 600;
    margin-top: 20px;
}
.particles{
    position: absolute;
    width: 100%!important;
    height: 30vh;
    margin-top: 20px;
    padding-bottom: 10px;
}
.page-section{
    margin-top: 20px !important;
    padding-top: 50px!important;
    padding-bottom: 50px!important;
    background-color: #ffffff;
    min-height: 70vh;
}
.Timeline-line{
    background-color: gray!important;
    z-index: 0;
}
button{
    border-radius: 0 !important;

}
.mainMenu{
    background-color: teal !important;
    background-color: var(--color-primary) !important;
    border: 0 !important;
}
.mainMenu .item{
    font-weight: bold  !important;

}
.timeLine div .Timeline-line{
    margin-top: 30px;
    margin-bottom: 20px;
}
.timeLine div .grid .row div{
    text-align: center!important;
    box-shadow: none!important;
    z-index:2;
}
.timeLine div .grid .row div i{
    box-shadow: none!important;
}
i.inverted.bordered.blue.icon, i.inverted.circular.blue.icon{
    background-color: teal !important;
    background-color: var(--color-primary) !important;
    z-index: 1;
}
.ui.blue.label, .ui.blue.labels .label, button.blue, a.blue{
    background-color: teal !important;
    background-color: var(--color-primary) !important;
}
a.blue.label{
    color: teal !important;
    color: var(--color-primary) !important;
}
.SlideIn-appear {
    -webkit-transform: translateX(30px);
            transform: translateX(30px);
    opacity: 0;
}
.SlideIn-appear.SlideIn-appear-active {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);;
    -webkit-transition: all 0.6s linear;;
    transition: all 0.6s linear;
}
.SlideIn-enter {
    opacity: 0;
    -webkit-transform: translateX(30px);
            transform: translateX(30px);
}
.SlideIn-enter.SlideIn-enter-active {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-transition: all 0.2s linear 0.4s;
    transition: all 0.2s linear 0.4s;
}
.SlideIn-leave {
    opacity: 1.0;
    -webkit-transform: translateX(0);
            transform: translateX(0);
}
.SlideIn-leave.SlideIn-leave-active {
    opacity: 0;
    position: absolute;
    width: 100%;
    -webkit-transform: translateX(-30px);
            transform: translateX(-30px);
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
}
.loading-segment{
    height: 100vh;
    width: 100%;
}
a.tableLink{
    color: #0d71bb;

}
.full-size{
    width: 100% !important;
}
@media (max-width: 960px) {
    .introName{
        font-size: 2em;
    }
}
